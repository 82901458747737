import { NavigationProps, routes } from "../../etc";
import { Container, Text, Switch } from "../../ui";
import { useLocation } from "react-router-dom";
import { NavPanel, Search } from "../index";
import { useSearch } from "../../hooks";
import React from "react";

const Navigation = ({
  visible,
  setSearchedItemLink,
  activeWeek,
  setActiveWeek,
}: NavigationProps) => {
  const { searchTitle, placeholder, result, trigger } = useSearch(activeWeek);
  const location = useLocation();

  const width = window.innerWidth;
  return (
    <Container
      padding={width >= 425 ? "0 40px" : undefined}
      width={width >= 425 ? "90%" : "90%"}
      display={visible ? "flex" : "none"}
      justify={"space-between"}
      gap={"20px 10px"}
      margin={"0 auto"}
      align={"center"}
      as={"section"}
      wrap={"wrap"}
    >
      <Container
        justify={"space-between"}
        direction={"column"}
        width={"420px"}
        as={"article"}
      >
        <Text as={"h3"} size={18}>
          Показывать расписание
        </Text>
        <NavPanel />
      </Container>
      <Container
        justify={"space-between"}
        direction={"column"}
        width={"360px"}
        as={"article"}
      >
        <Text as={"h3"} size={18}>
          {searchTitle}
        </Text>
        <Search
          setSearchedValue={setSearchedItemLink}
          placeholder={placeholder}
          searchQuery={trigger}
          result={result}
          activeWeek={activeWeek}
        />
      </Container>
      {location.pathname === routes.exams ? (
        <Container
          justify={"space-between"}
          direction={"column"}
          width={"250px"}
          as={"article"}
        >
          <Text as={"h3"} size={18}>
            Искать по
          </Text>
          <Switch
            exams
            activeIndex={activeWeek}
            setActiveIndex={setActiveWeek}
          />
        </Container>
      ) : (
        <Container
          justify={"space-between"}
          direction={"column"}
          width={"200px"}
          as={"article"}
        >
          <Text as={"h3"} size={18}>
            Неделя
          </Text>
          <Switch activeIndex={activeWeek} setActiveIndex={setActiveWeek} />
        </Container>
      )}
    </Container>
  );
};

export default Navigation;
