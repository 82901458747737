import React from "react";
import { ExamType } from "../../etc/types";
import Card from "./Card";
import { Container } from "../../ui";

const ExamsList = ({ list }: { list: ExamType[] }) => {
  return (
    <Container
      as={"section"}
      margin={"60px 0 0 0"}
      direction={"column"}
      gap={"12px"}
    >
      {list?.map(
        ({
          group,
          teacher,
          date,
          classroom,
          subject,
          time,
          "additional-info": additional_info = "",
        }: ExamType) => (
          <Card
            key={`${date}-${teacher}-${time}-${group}-${new Date()}`}
            additional_info={additional_info}
            lesson={date + " " + time}
            classroom={classroom}
            subject={subject}
            teacher={teacher}
            group={group}
          />
        ),
      )}
    </Container>
  );
};

export default ExamsList;
