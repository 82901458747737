import { LegacyRef, useEffect, useRef, useState } from "react";
import { useVisibleReturn } from "../etc";

const useClickOutside = (): useVisibleReturn => {
  const [isVisible, setVisible] = useState(false);
  const wrapperRef: LegacyRef<HTMLElement> = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as HTMLElement)
      ) {
        setVisible(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const makeVisible = () => {
    setVisible(true);
  };
  const hide = () => {
    setVisible(false);
  };

  return [wrapperRef, isVisible, makeVisible, hide];
};

export default useClickOutside;
