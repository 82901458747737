import { colors, ActiveButtonListItemProps } from "../etc";
import { StyledButton } from "./Button";
import React from "react";

const ActiveButtonListItem = ({
  name,
  isActive,
  HandleClick,
  style,
}: ActiveButtonListItemProps) => {
  return (
    <StyledButton
      style={style}
      initial={{
        backgroundColor: colors.mGray,
        color: colors.lBlack,
      }}
      animate={{
        backgroundColor: isActive ? colors.blue : colors.mGray,
        color: isActive ? colors.white : colors.lBlack,
      }}
      onClick={() => {
        HandleClick();
      }}
    >
      {name}
    </StyledButton>
  );
};
export default ActiveButtonListItem;
