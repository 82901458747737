import { createApi } from "@reduxjs/toolkit/query/react";
import { baseApiUrl, routes, SearchItemType } from "../etc";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const TeacherApi = createApi({
  reducerPath: routes.teachers,
  baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
  endpoints: (builder) => ({
    searchTeachers: builder.query<SearchItemType[], string>({
      query: (data: string) => ({
        url: `search`,
        params: {
          teacher: data,
        },
      }),
      transformResponse: (response: SearchItemType[]) => response,
    }),
  }),
});

export const { useLazySearchTeachersQuery } = TeacherApi;
