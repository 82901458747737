import { daysTypes, DayType } from "../../etc/types";
import { Container, Text } from "../../ui";
import Card from "./Card";
import React from "react";

const Day = ({ day, dayTitle }: { day: DayType[]; dayTitle: daysTypes }) => {
  return (
    <Container
      as={"li"}
      direction={"column"}
      gap={"12px"}
      margin={"60px 0 0 0"}
    >
      <Text as={"h2"} size={32} line={40}>
        {dayTitle}
      </Text>
      {day.length === 0 && (
        <Container justify={"center"} margin={"10px 0"}>
          <Text as={"h3"} size={40} line={50}>
            Нет занятий
          </Text>
        </Container>
      )}
      {day.length !== 0 &&
        day.map(
          ({
            "lesson-type": type,
            "additional-info": additional_info,
            classroom,
            subgroup,
            subject,
            teacher,
            lesson,
            group,
          }: DayType) => (
            <Card
              key={`${classroom}-${subject}-${subgroup}-${classroom}-${teacher}-${lesson}-${type}-${dayTitle}-${group}-${new Date()}`}
              additional_info={additional_info}
              classroom={classroom}
              subgroup={subgroup}
              subject={subject}
              teacher={teacher}
              lesson={lesson}
              group={group}
              type={type}
            />
          ),
        )}
    </Container>
  );
};

export default Day;
