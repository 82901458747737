import { createApi } from "@reduxjs/toolkit/query/react";
import { baseApiUrl, ScheduleListResponse, SearchResponse } from "../etc";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ExamType } from "../etc/types";

export const ItemApi = createApi({
  reducerPath: "item",
  baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
  endpoints: (builder) => ({
    getItem: builder.query<
      SearchResponse<ScheduleListResponse | ExamType[]>,
      string
    >({
      query: (data: string) => ({
        url: `${data}`,
      }),
      transformResponse: (
        response: SearchResponse<ScheduleListResponse | ExamType[]>,
      ) => response,
    }),
  }),
});

export const { useLazyGetItemQuery } = ItemApi;
