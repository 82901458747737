import React from "react";
import { Container, Text } from "../../ui";
import { lessonsTime, routes, CardProps } from "../../etc";
import { useLocation } from "react-router-dom";

const Card = (props: CardProps) => {
  const {
    classroom,
    subgroup,
    lesson,
    subject,
    teacher,
    type,
    group,
    additional_info,
  } = props;

  const location = useLocation();
  if (Object.values(props).includes("undefined undefined" || undefined)) {
    return <></>;
  }

  const width = window.innerWidth;

  return (
    <Container
      as={"section"}
      justify={"space-between"}
      gap={"40px"}
      wrap={width < 768 ? "wrap" : undefined}
    >
      <Container
        wrap={width < 425 ? "wrap" : undefined}
        direction={"column"}
        width={"110px"}
        as={"article"}
        gap={"12px"}
      >
        <Text size={18} line={24} weight={800} color={"blue"}>
          {location.pathname !== routes.exams ? lesson : lesson.split(" ")[0]}
        </Text>
        <Text size={18} line={24} weight={600} color={"dGray"}>
          {lessonsTime[lesson] || lesson.split(" ")[1]}
        </Text>
      </Container>
      <Container
        wrap={width < 425 ? "wrap" : undefined}
        direction={"column"}
        padding={"24px"}
        color={"lGray"}
        as={"article"}
        radius={20}
      >
        <Container
          wrap={width < 425 ? "wrap" : undefined}
          color={"lGray"}
          justify={"space-between"}
          gap={"24px"}
        >
          {subject && (
            <Text as={"h3"} size={24} weight={600} line={30}>
              {subject[0].toUpperCase() + subject.slice(1).toLowerCase()}
            </Text>
          )}
          <Container
            wrap={width < 425 ? "wrap" : undefined}
            direction={"column"}
            align={"flex-end"}
            color={"lGray"}
            as={"ul"}
          >
            {location.pathname === routes.teachers
              ? group?.split(" ").map((name: string) => (
                  <Text
                    size={18}
                    weight={600}
                    line={24}
                    key={`${name}${new Date()}`}
                  >
                    {name}
                  </Text>
                ))
              : teacher?.split("    ").map((name: string) => (
                  <Text
                    size={18}
                    weight={600}
                    line={24}
                    key={`${name}${new Date()}`}
                  >
                    {name}
                  </Text>
                ))}
          </Container>
        </Container>
        <Container
          wrap={width < 425 ? "wrap" : undefined}
          margin={"30px 0 0 0"}
          color={"lGray"}
          gap={"24px"}
        >
          {type && (
            <Text as={"p"} size={18} weight={600} line={24}>
              {type[0].toUpperCase() + type.slice(1).toLowerCase()}
            </Text>
          )}
          {location.pathname !== routes.auditoriums && (
            <Text as={"p"} size={18} weight={600} line={24} color={"dGray"}>
              Аудитория {classroom}
            </Text>
          )}
          <Text as={"p"} size={18} weight={600} line={24} color={"dGray"}>
            {subgroup ? `Подгрупа ${subgroup}` : ""}
          </Text>
          {location.pathname === routes.auditoriums && (
            <Container color={"lGray"} direction={"column"}>
              {group?.split(" ").map((name: string) => (
                <Text
                  size={18}
                  weight={600}
                  line={24}
                  key={`${name}${new Date()}`}
                >
                  {name}
                </Text>
              ))}
            </Container>
          )}
          {location.pathname === routes.exams && (
            <Text
              size={18}
              weight={600}
              line={24}
              key={`${group}${new Date()}`}
            >
              {group}
            </Text>
          )}
        </Container>
        {additional_info && (
          <Text
            as={"p"}
            style={{
              backgroundColor: "#ffffff",
              width: "100%",
              margin: "20px 0 0 0  ",
              padding: "10px",
              borderRadius: "10px",
            }}
            size={18}
            weight={600}
            line={24}
            color={"dGray"}
          >
            {additional_info}
          </Text>
        )}
      </Container>
    </Container>
  );
};

export default Card;
