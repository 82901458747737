import React from "react";
import { motion } from "framer-motion";
import { ButtonProps, colors, StyledButtonProps } from "../etc";
import styled from "styled-components";

export const StyledButton = styled(motion.button)<StyledButtonProps>`
  color: ${(props) => (props.color ? colors[props.color] : colors.black)};
  padding: ${(props) => props.padding || "12px 14px"};
  line-height: ${(props) => `${props.line}px` || "18px"};
  font-size: ${(props) => `${props.size}px` || "16px"};
  font-weight: ${(props) => props.weight || 600};
  font-family: "Raleway", sans-serif;
  background-color: ${colors["mGray"]};
  align-items: center;
  border-radius: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  outline: none;
  border: none;
`;

const Button = ({
  type = "button",
  children,
  onClick,
  padding,
  weight,
  theme,
  color,
  size,
  line,
}: ButtonProps) => {
  return (
    <StyledButton
      whileHover={{
        backgroundColor:
          theme === "dark"
            ? colors.lBlack
            : theme === "red"
              ? colors.red
              : colors.blue,
        color: colors.white,
      }}
      onClick={onClick}
      padding={padding}
      weight={weight}
      theme={theme}
      color={color}
      size={size}
      line={line}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
