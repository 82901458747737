import { createGlobalStyle } from "styled-components";
import Thin from "./etc/fonts/Raleway-Thin.ttf";
import ELight from "./etc/fonts/Raleway-ExtraLight.ttf";
import Light from "./etc/fonts/Raleway-Light.ttf";
import Normal from "./etc/fonts/Raleway-Regular.ttf";
import Medium from "./etc/fonts/Raleway-Medium.ttf";
import SBold from "./etc/fonts/Raleway-SemiBold.ttf";
import Bold from "./etc/fonts/Raleway-Bold.ttf";
import EBold from "./etc/fonts/Raleway-ExtraBold.ttf";
import Black from "./etc/fonts/Raleway-Black.ttf";
import { colors } from "./etc";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "font";
    src: url(${Thin});
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: "font";
    src: url(${ELight});
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: "font";
    src: url(${Light});
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "font";
    src: url(${Normal});
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "font";
    src: url(${Medium});
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "font";
    src: url(${SBold});
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "font";
    src: url(${Bold});
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "font";
    src: url(${EBold});
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: "font";
    src: url(${Black});
    font-weight: 900;
    font-style: normal;;
  }

  * ::-webkit-scrollbar {
    opacity: 0;
    width: 5px;
    height: 5px;
  }

  * ::-webkit-scrollbar-thumb {
    background-color: ${colors.dGray};
    border-radius: 10px;
  }
  
  html {
    font-size: 16px;
	  line-height: 18px;
  }
`;

export default GlobalStyle;
