import React from "react";
import { Container } from "../../ui";
import { WeekType } from "../../etc";
import Day from "./Day";

const Week = ({ week }: { week: WeekType }) => {
  return (
    <>
      <Container as={"section"} direction={"column"}>
        <Day day={week["Понедельник"] || []} dayTitle={"Понедельник"} />
        <Day day={week["Вторник"] || []} dayTitle={"Вторник"} />
        <Day day={week["Среда"] || []} dayTitle={"Среда"} />
        <Day day={week["Четверг"] || []} dayTitle={"Четверг"} />
        <Day day={week["Пятница"] || []} dayTitle={"Пятница"} />
        <Day day={week["Суббота"] || []} dayTitle={"Суббота"} />
      </Container>
    </>
  );
};

export default Week;
