import { setupListeners } from "@reduxjs/toolkit/query/react";
import { configureStore } from "@reduxjs/toolkit";
import { AuditoriumApi } from "./auditoriums";
import { TeacherApi } from "./teachers";
import { GroupsApi } from "./groups";
import { ItemApi } from "./item";
import { ExamsApi } from "./exams";

export const store = configureStore({
  reducer: {
    [AuditoriumApi.reducerPath]: AuditoriumApi.reducer,
    [TeacherApi.reducerPath]: TeacherApi.reducer,
    [GroupsApi.reducerPath]: GroupsApi.reducer,
    [ExamsApi.reducerPath]: ExamsApi.reducer,
    [ItemApi.reducerPath]: ItemApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(AuditoriumApi.middleware)
      .concat(TeacherApi.middleware)
      .concat(GroupsApi.middleware)
      .concat(ItemApi.middleware)
      .concat(ExamsApi.middleware),
});

setupListeners(store.dispatch);
