import React from "react";
import { Text } from "../../ui";
import { initWeek, routes, SearchItemType, WeekType } from "../../etc";

export type TitleProps = {
  schedule: WeekType;
  location: string;
  searchedItemLink: SearchItemType;
  storage: SearchItemType | null;
};

const Title = ({
  schedule,
  location,
  searchedItemLink,
  storage,
}: TitleProps) => (
  <Text as={"h1"} size={45} line={60}>
    {location === routes.auditoriums &&
      JSON.stringify(schedule) !== JSON.stringify(initWeek) &&
      "Аудитория "}
    {searchedItemLink.name !== ""
      ? searchedItemLink.name
      : storage !== null
        ? storage.name
        : ""}
  </Text>
);

export default Title;
