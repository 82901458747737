import styled from "styled-components";
import { colors, ContainerProps } from "../etc/";

export const Container = styled.div<ContainerProps>`
  flex-flow: ${(props) => props.direction || "row"}
    ${(props) =>
      props.wrap
        ? props.wrap === "wrap"
          ? "wrap"
          : "wrap-reverse"
        : "nowrap"};
  background-color: ${(props) => colors[props.color ? props.color : "white"]};
  justify-content: ${(props) => props.justify || "flex-start"};
  border-radius: ${(props) => `${props.radius}px` || "0"};
  align-items: ${(props) => props.align || "flex-start"};
  max-width: ${(props) => (props.width ? `${props.width}` : "1440px")};
  height: ${(props) => `${props.height}px` || "auto"};
  max-height: ${(props) => (props.mHeight ? `${props.mHeight}px` : "auto")};
  position: ${(props) => (props.position ? props.position : "static")};
  display: ${(props) => props.display || "flex"};
  border: ${(props) => props.border || "none"};
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
  gap: ${(props) => props.gap || 0};
  width: 100%;
  overflow-y: ${(props) => props.scroll || "inherit"};
`;
