import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { baseApiUrl, routes, SearchItemType } from "../etc";

export const GroupsApi = createApi({
  reducerPath: routes.groups,
  baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
  endpoints: (builder) => ({
    getGroups: builder.query<SearchItemType[], void>({
      query: () => ({
        url: `groups`,
      }),
      transformResponse: (response: SearchItemType[]) => response,
    }),
  }),
});

export const { useGetGroupsQuery } = GroupsApi;
