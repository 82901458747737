import styled from "styled-components";

export const Input = styled.input`
  line-height: 16px;
  font-weight: 600;
  font-size: 14px;
  outline: none;
  border: none;
  width: 100%;
`;
