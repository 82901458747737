import React from "react";
import { Image, Container, Text } from ".";

const Loader = () => {
  return (
    <>
      <Container
        as={"section"}
        gap={"50px"}
        align={"center"}
        justify={"center"}
      >
        <Image
          src="/assets/base/uilogo.svg"
          height={100}
          width={100}
          initial={{ rotate: 0 }}
          animate={{ rotate: 360 }}
          transition={{
            repeat: Infinity,
            ease: "linear",
            duration: 5,
          }}
        />
        <Text size={50}>Loading . . .</Text>
      </Container>
    </>
  );
};

export default Loader;
