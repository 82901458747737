import styled from "styled-components";
import { TextProps, colors } from "../etc";

export const Text = styled.p<TextProps>`
  border-bottom: ${(props) =>
    props.underline === "solid"
      ? `2px ${colors[props.color ? props.color : "black"]} solid`
      : "none"};
  color: ${(props) => (props.color ? colors[props.color] : colors.black)};
  line-height: ${(props) => `${props.line}px` || "18px"};
  font-size: ${(props) => `${props.size}px` || "16px"};
  font-weight: ${(props) => props.weight || 600};
  font-family: "font", sans-serif;
  font-feature-settings:
    "pnum" on,
    "lnum" on,
    "liga" off;

  @media screen and (max-width: 425px) {
    font-size: ${(props) =>
      props.size ? `${(props.size * 2) / 3}px` : "12px"};
    font-size: ${(props) =>
      props.line ? `${(props.line * 2) / 3}px` : "14px"};
  }
`;
