import React from "react";
import { menuItemList, NavItemData, routes } from "../../etc";
import { Container, ActiveButtonListItem } from "../../ui";
import { useLocation, useNavigate } from "react-router-dom";

const NavPanel = () => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = React.useState<number>(
    menuItemList.indexOf(
      menuItemList.filter(({ route }: NavItemData) =>
        location.pathname !== "/"
          ? route === location.pathname.replace(/\//g, "")
          : route === "groups",
      )[0],
    ),
  );
  const navigate = useNavigate();

  const width = window.innerWidth;

  return (
    <Container
      as={"nav"}
      gap={"10px"}
      margin={"12px 0 0 0"}
      wrap={width < 768 ? "wrap" : undefined}
    >
      {menuItemList.map(({ name, route }: NavItemData, index: number) => (
        <ActiveButtonListItem
          isActive={activeIndex === index}
          HandleClick={() => {
            setActiveIndex(index);
            navigate(routes[route]);
          }}
          name={name}
          key={index}
        />
      ))}
    </Container>
  );
};

export default NavPanel;
