import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyle from "./globalStyle";
import { Provider } from "react-redux";
import { store } from "./api/store";
import Layout from "./pages/Layout";
import { routes } from "./etc";
import React from "react";

const App = () => {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path={routes.groups} element={<Layout />} />
          <Route path={routes.teachers} element={<Layout />} />
          <Route path={routes.auditoriums} element={<Layout />} />
          <Route path={routes.exams} element={<Layout />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
