import {
  colors,
  responseSearchResultType,
  routes,
  SearchItemType,
} from "../../etc";
import { Container, Input, Loader, Text } from "../../ui";
import { useLocation } from "react-router-dom";
import { useDebounce, useClickOutside } from "../../hooks";
import React from "react";

export type SearchProps = {
  setSearchedValue: React.Dispatch<React.SetStateAction<SearchItemType>>;
  searchQuery: (debounced: string) => void;
  result: responseSearchResultType;
  placeholder: string;
  activeWeek: number;
};

const Search = ({
  placeholder,
  result,
  searchQuery,
  setSearchedValue,
  activeWeek,
}: SearchProps) => {
  const [inputValue, setInputValue] = React.useState<string>("");
  const debounced = useDebounce(inputValue);

  const [wrapperRef, isVisibleSearchItems, makeVisible, hide] =
    useClickOutside();

  const location = useLocation();
  React.useEffect(() => {
    setInputValue("");
  }, [location.pathname]);
  React.useEffect(() => {
    if (location.pathname === routes.exams) setInputValue("");
  }, [location.pathname, activeWeek]);

  React.useEffect(() => {
    if (debounced.length > 0) {
      searchQuery(debounced);
      const timer = setTimeout(() => makeVisible(), 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [debounced]);

  return (
    <article ref={wrapperRef} style={{ maxWidth: "360px", width: "100%" }}>
      <Container
        border={`1px solid ${colors.black}`}
        padding={"5px 5px 5px 15px"}
        justify={"space-between"}
        margin={"12px 0 0 0"}
        height={40}
        align={"center"}
        radius={10}
        position={"relative"}
      >
        <Input
          onFocus={() => makeVisible()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.target.value)
          }
          placeholder={placeholder}
          value={inputValue}
        />
        {isVisibleSearchItems &&
          inputValue.length > 0 &&
          result.data &&
          result.isSuccess && (
            <Container
              border={`1px solid ${colors.black}`}
              padding={"10px 20px"}
              position={"absolute"}
              style={{ top: "50px", left: "0" }}
              direction={"column"}
              width={"360px"}
              radius={10}
              as={"ul"}
              mHeight={300}
              scroll={"auto"}
            >
              {result.isError && (
                <Text color={"red"}>Что-то пошло не так =/</Text>
              )}
              {result.isLoading && <Loader />}
              {result.data.length === 0 && result.isSuccess && (
                <Text>Ничего не найдено</Text>
              )}
              {result.data.length !== 0 &&
                result.isSuccess &&
                result.data.map(
                  (
                    { name, "schedule-link": link }: SearchItemType,
                    index: number,
                  ) => (
                    <li
                      key={link}
                      style={{
                        borderBottom: result.data?.length
                          ? index === result.data.length - 1
                            ? "none"
                            : `1px solid ${colors.dGray}`
                          : `1px solid ${colors.dGray}`,
                        listStyle: "none",
                        padding: "10px",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          hide();
                          setSearchedValue({
                            name: name,
                            "schedule-link": link,
                          });
                        }}
                      >
                        {name}
                      </Text>
                    </li>
                  ),
                )}
            </Container>
          )}
      </Container>
    </article>
  );
};

export default Search;
