import { createApi } from "@reduxjs/toolkit/query/react";
import { baseApiUrl, routes, SearchItemType } from "../etc";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const AuditoriumApi = createApi({
  reducerPath: routes.auditoriums,
  baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
  endpoints: (builder) => ({
    searchAuditorium: builder.query<SearchItemType[], string>({
      query: (data: string) => ({
        url: `search`,
        params: {
          classroom: data,
        },
      }),
      transformResponse: (response: SearchItemType[]) => response,
    }),
  }),
});

export const { useLazySearchAuditoriumQuery } = AuditoriumApi;
