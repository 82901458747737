import {
  NavController,
  Navigation,
  Title,
  ExamsList,
  Week,
} from "../components";
import { Header, Container, Button, Loader, Text } from "../ui";
import { useLazyGetItemQuery } from "../api/item";
import {
  initWeek,
  WeekType,
  SearchItemType,
  VisibleMenuButtonText,
  initSearchedItemLink,
  routes,
  weekTypes,
} from "../etc";
import React from "react";
import { useLocation } from "react-router-dom";
import { useGetExamsQuery } from "../api/exams";
import { ExamType, ScheduleListResponse } from "../etc/types";

const Layout = () => {
  const location = useLocation();

  const width = window.innerWidth;

  const { data, isLoading, isSuccess } = useGetExamsQuery();

  const weekType = () => {
    const now = new Date();
    const startYear = new Date(now.getFullYear(), 0, 1).getTime();
    
    const daysAfterStartYear = Math.ceil((now.getTime() - startYear)/(24*3600*1000));
    return Math.ceil(daysAfterStartYear / 7) % 2;
  };

  const [examList, setExamList] = React.useState<ExamType[]>(data || []);
  const [isVisible, setVisible] = React.useState<boolean>(true);
  const [activeWeek, setActiveWeek] = React.useState<number>(weekType());
  const [buttonText, setButtonText] =
    React.useState<VisibleMenuButtonText>("Скрыть меню");
  const [searchedItemLink, setSearchedItemLink] =
    React.useState<SearchItemType>(initSearchedItemLink);
  const [schedule, setSchedule] = React.useState<WeekType>(initWeek);
  const [trigger, searchItemResponse] = useLazyGetItemQuery();

  const [storage, setStorage] = React.useState(
    JSON.parse(localStorage.getItem(`schedule_${location.pathname}`) as string),
  );

  React.useMemo(
    () =>
      setStorage(
        JSON.parse(
          localStorage.getItem(`schedule_${location.pathname}`) as string,
        ),
      ),
    [location.pathname],
  );

  React.useEffect(
    () => setSearchedItemLink(initSearchedItemLink),
    [location.pathname],
  );

  React.useEffect(() => {
    if (location.pathname === routes.exams)
      setSearchedItemLink(initSearchedItemLink);
  }, [location.pathname, activeWeek]);

  React.useEffect(() => {
    if (
      storage === null &&
      JSON.stringify(searchedItemLink) === JSON.stringify(initSearchedItemLink)
    )
      setSchedule(initWeek);
  }, [searchedItemLink, storage]);

  React.useEffect(() => {
    if (storage !== null && searchedItemLink === initSearchedItemLink)
      trigger(storage["schedule-link"]);
    if (searchedItemLink !== initSearchedItemLink)
      trigger(searchedItemLink["schedule-link"]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedItemLink, storage]);

  React.useEffect(() => {
    if (
      (storage ||
        JSON.stringify(searchedItemLink) !==
        JSON.stringify(initSearchedItemLink)) &&
      searchItemResponse.data &&
      location.pathname !== routes.exams
    ) {
      console.log(searchItemResponse.data, activeWeek);
 
      const response = searchItemResponse.data as unknown as ScheduleListResponse;
      setSchedule(
        JSON.parse(
          JSON.stringify(response[weekTypes[activeWeek].toLowerCase() as 'верхняя' | 'нижняя'] as WeekType),
        ),
      );
    }
  }, [
    activeWeek,
    location.pathname,
    searchItemResponse,
    searchedItemLink,
    storage,
  ]);

  React.useEffect(() => {
    if (
      JSON.stringify(searchedItemLink) !==
      JSON.stringify(initSearchedItemLink) &&
      searchItemResponse.data &&
      location.pathname === routes.exams
    )
      setExamList(
        JSON.parse(JSON.stringify(Object.values(searchItemResponse.data))),
      );
  }, [activeWeek, location.pathname, searchItemResponse, searchedItemLink]);

  React.useEffect(() => {
    if (
      location.pathname === routes.exams &&
      JSON.stringify(searchedItemLink) ===
      JSON.stringify(initSearchedItemLink) &&
      isSuccess &&
      !isLoading
    )
      setExamList(data);
  }, [
    location.pathname,
    activeWeek,
    data,
    searchedItemLink,
    isSuccess,
    isLoading,
  ]);

  const ChangeVisibilityHandler = () => {
    buttonText === "Скрыть меню"
      ? setButtonText("Показать меню")
      : setButtonText("Скрыть меню");
    setVisible(!isVisible);
  };

  const saveValue = () => {
    localStorage.setItem(
      `schedule_${location.pathname}`,
      JSON.stringify(searchedItemLink),
    );
    setStorage(searchedItemLink);
  };
  const removeValue = () => {
    localStorage.removeItem(`schedule_${location.pathname}`);
    setStorage(null);
  };

  const btn = [
    { name: "группу", route: "/" },
    { name: "преподавателя", route: "/teachers/" },
    { name: "аудиторию", route: "/auditoriums/" },
    { name: "экзамен", route: "/exams/" },
  ];

  return (
    <>
      <Header>
        <NavController
          ChangeVisibilityHandler={ChangeVisibilityHandler}
          buttonText={buttonText}
        />
        <Navigation
          setSearchedItemLink={setSearchedItemLink}
          setActiveWeek={setActiveWeek}
          activeWeek={activeWeek}
          visible={isVisible}
        />
      </Header>
      <Container
        direction={"column"}
        padding={"0 40px"}
        gap={"20px 10px"}
        margin={"0 auto"}
        as={"section"}
      >
        {(searchedItemLink.name !== "" || (storage && storage.name !== "")) && (
          <Container
            gap={"30px"}
            margin={"60px 0 0 0"}
            align={"center"}
            as={"section"}
            wrap={width < 425 ? "wrap" : undefined}
          >
            <Title
              searchedItemLink={searchedItemLink}
              location={location.pathname}
              schedule={schedule}
              storage={storage}
            />
            {location.pathname !== routes.exams && (
              <>
                {storage &&
                  (searchedItemLink["schedule-link"] ===
                    storage["schedule-link"] ||
                    (storage["schedule-link"] &&
                      !searchedItemLink["schedule-link"])) ? (
                  <Button theme={"red"} onClick={() => removeValue()}>
                    Забыть{" "}
                    {
                      btn.filter(({ route }) => route === location.pathname)[0]
                        .name
                    }
                  </Button>
                ) : (
                  <Button onClick={() => saveValue()}>
                    Запомнить{" "}
                    {
                      btn.filter(({ route }) => route === location.pathname)[0]
                        .name
                    }
                  </Button>
                )}
              </>
            )}
          </Container>
        )}
        {location.pathname !== routes.exams ? (
          <>
            {searchItemResponse.isLoading && <Loader />}
            {JSON.stringify(initWeek) === JSON.stringify(schedule) ? (
              <Container
                as={"section"}
                justify={"center"}
                margin={"90px 0 0 0"}
              >
                <Text as={"h2"} size={50} line={70} weight={700}>
                  Занятий не найдено
                </Text>
              </Container>
            ) : (
              searchItemResponse.isSuccess && <Week week={schedule} />
            )}
          </>
        ) : (
          <>
            {(searchItemResponse.isLoading || isLoading) && <Loader />}
            {!examList.length &&
              (searchItemResponse.isSuccess || isSuccess) &&
              !(searchItemResponse.isLoading || isLoading) && (
                <Container
                  as={"section"}
                  justify={"center"}
                  margin={"90px 0 0 0"}
                >
                  <Text
                    as={"h2"}
                    size={50}
                    line={70}
                    weight={700}
                    style={{ textAlign: "center" }}
                  >
                    Нет экзаменов
                  </Text>
                </Container>
              )}
            {(searchItemResponse.isSuccess || isSuccess) &&
              !(searchItemResponse.isLoading || isLoading) && (
                <ExamsList list={examList} />
              )}
          </>
        )}
      </Container>
    </>
  );
};

export default Layout;
