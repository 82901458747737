import { createApi } from "@reduxjs/toolkit/query/react";
import { baseApiUrl, routes, SearchItemType } from "../etc";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ExamType } from "../etc/types";

export type ExamInputType = { group?: string; teacher?: string; date?: string };

export const ExamsApi = createApi({
  reducerPath: routes.exams,
  baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
  endpoints: (builder) => ({
    getExams: builder.query<ExamType[], void>({
      query: () => `exams`,
      transformResponse: (response: ExamType[]) => response,
    }),
    searchExams: builder.query<SearchItemType[], string>({
      query: (data) => {
        return {
          url: `search/exams?${data}`,
        };
      },
      transformResponse: (response: SearchItemType[]) => response,
    }),
  }),
});

export const {
  useGetExamsQuery,
  useLazyGetExamsQuery,
  useLazySearchExamsQuery,
  useSearchExamsQuery,
} = ExamsApi;
