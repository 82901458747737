import { useLazySearchAuditoriumQuery } from "../api/auditoriums";
import { useLazySearchTeachersQuery } from "../api/teachers";
import { useCallback, useEffect, useState } from "react";
import { useGetGroupsQuery } from "../api/groups";
import { useLocation } from "react-router-dom";
import {
  responseSearchResultType,
  useSearchResponse,
  SearchItemType,
  routes,
} from "../etc";
import { useLazySearchExamsQuery } from "../api/exams";
const useSearch = (activeExamSearch: number): useSearchResponse => {
  const location = useLocation();

  const [searchTitle, setSearchTitle] = useState<string>("Группа");
  const [placeholder, setPlaceholder] = useState<string>("Введите группу");

  const [searchTeacher, teacher] = useLazySearchTeachersQuery();
  const [searchAuditorium, auditorium] = useLazySearchAuditoriumQuery();
  const [searchExam, exams] = useLazySearchExamsQuery();

  const [result, setResult] = useState<responseSearchResultType>(teacher);

  const {
    fulfilledTimeStamp,
    startedTimeStamp,
    isUninitialized,
    endpointName,
    currentData,
    isFetching,
    requestId,
    isLoading,
    isSuccess,
    isError,
    status,
    data: groups,
  } = useGetGroupsQuery();

  const searchGroup = (debounced: string) => {
    const filtered = groups?.filter(({ name }: SearchItemType) =>
      name.toLowerCase().includes(debounced.toLowerCase()),
    );
    setResult({
      fulfilledTimeStamp,
      startedTimeStamp,
      isUninitialized,
      endpointName,
      currentData,
      isFetching,
      requestId,
      isLoading,
      isSuccess,
      isError,
      status,
      data: filtered,
    });
  };

  const query = (debounced: string) => {
    if (location.pathname === routes.exams && activeExamSearch === 1)
      searchExam(`teacher=${debounced}`);
    if (location.pathname === routes.exams && activeExamSearch === 0)
      searchExam(`group=${debounced}`);
    if (location.pathname === routes.auditoriums) searchAuditorium(debounced);
    if (location.pathname === routes.teachers) searchTeacher(debounced);
    if (location.pathname === routes.groups) searchGroup(debounced);
  };

  const setHeaders = useCallback(
    (url: string) => {
      if (url === routes.teachers) {
        setSearchTitle("Преподаватель");
        setPlaceholder("Введите преподавателя");
        setResult(teacher);
      }
      if (url === routes.auditoriums) {
        setSearchTitle("Аудитория");
        setPlaceholder("Введите номер аудитории");
        setResult(auditorium);
      }
      if (url === routes.groups) {
        setSearchTitle("Группа");
        setPlaceholder("Введите группу");
      }
      if (url === routes.exams && activeExamSearch === 0) {
        setSearchTitle("Группа");
        setPlaceholder("Введите группу");
        setResult(exams);
      }
      if (url === routes.exams && activeExamSearch === 1) {
        setSearchTitle("Преподаватель");
        setPlaceholder("Введите преподавателя");
        setResult(exams);
      }
    },
    [activeExamSearch, teacher, auditorium, exams],
  );
  useEffect(() => {
    setHeaders(location.pathname);
  }, [setHeaders, location.pathname]);

  return { searchTitle, placeholder, trigger: query, result };
};
export default useSearch;
