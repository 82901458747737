import React from "react";
import { Text, Image, Container } from "../../ui";
import { NavControllerProps } from "../../etc";

const NavController = ({
  ChangeVisibilityHandler,
  buttonText,
}: NavControllerProps) => {
  const width = window.innerWidth;
  return (
    <Container
      padding={width >= 425 ? "0 40px" : undefined}
      width={width >= 425 ? "90%" : "90%"}
      margin={"0 auto 12px auto"}
      align={"center"}
      as={"section"}
      gap={"50px"}
      height={60}
    >
      <figure>
        <Image height={50} src="/assets/base/logo.svg" />
      </figure>
      <Text
        onClick={() => ChangeVisibilityHandler()}
        underline={"solid"}
        color={"dGray"}
        line={24}
        size={18}
        style={{ cursor: "pointer" }}
      >
        {buttonText}
      </Text>
    </Container>
  );
};

export default NavController;
