import React from "react";
import { Container, ActiveButtonListItem } from "./";
import { SwitchProps, weekTypes } from "../etc";
import { examSearch } from "../etc";

const Switch = ({ activeIndex, setActiveIndex, exams }: SwitchProps) => {
  return (
    <Container
      justify={"space-between"}
      margin={"12px 0 0 0"}
      align={"center"}
      color={"mGray"}
      padding={"5px"}
      width={exams ? "250px" : "200px"}
      gap={"5px"}
      height={40}
      radius={20}
      as={"div"}
    >
      {!exams &&
        weekTypes.map((element: string, index: number) => (
          <ActiveButtonListItem
            HandleClick={() => setActiveIndex(index)}
            isActive={activeIndex === index}
            style={{ padding: "8px 16px" }}
            name={element}
            key={index}
          />
        ))}
      {exams &&
        examSearch.map((element: string, index: number) => (
          <ActiveButtonListItem
            HandleClick={() => setActiveIndex(index)}
            isActive={activeIndex === index}
            style={{ padding: "8px 16px" }}
            name={element}
            key={index}
          />
        ))}
    </Container>
  );
};

export default Switch;
